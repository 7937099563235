.profile__info {
  min-width: 0;
  max-width: 550px;
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
}
@media (max-width: 739px)  {
  .profile__info {
    align-items: center;
    padding: 0 0 36px 0;
  }
}
@media (max-width: 496px)  {
  .profile__info {
    padding: 0 10px 33px;
  }
}