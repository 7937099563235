.auth {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}








