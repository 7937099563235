.popup__close {
  position: absolute;
  top: -40px;
  right: -40px;
  min-width: 32px;
  min-height: 32px;
  background-image: url('../../../icons/close-icon.svg');
  cursor: pointer;
  transition: opacity .3s;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
}

.popup__close:hover {
  opacity: .6;
}

@media (max-width: 540px)  {
  .popup__close {
    right: 0;
  }
}
@media (max-width: 420px)  {
  .popup__close {
    background-size: 20px 20px;
    min-width: 20px;
    min-height: 20px;
    top: -36px;
  }
}