.profile__name {
  margin: 0;
  padding: 0 18px 0 0;
  font-size: 42px;
  font-weight: 500;
  line-height: 48px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 366px)  {
  .profile__name {
    font-size: 27px;
    line-height: 29px;
    padding: 0 10px 0 0;
  }
}