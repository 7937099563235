.popup__form {
  position: relative;
  background-color: #FFFFFF;
  color: #000000;
  display: flex;
  flex-direction: column;
  padding: 34px 36px 37px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 358px;
  flex: 1;
}

@media (max-width: 470px)  {
  .popup__form {
    margin: 0 19px;
  }
}

@media (max-width: 420px)  {
  .popup__form {
    padding: 25px 22px;
  }
}