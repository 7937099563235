.popup__btn {
  margin: 12px 0 0 0;
  padding: 0;
  background-color: #000000;
  color: #FFFFFF;
  min-height: 50px;
  border-color: #000000;
  border-radius: 2px;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  transition: opacity .3s;
}

.popup__btn:hover {
  opacity: .8;
}

@media (max-width: 420px)  {
  .popup__btn {
    font-size: 14px;
    line-height: 17px;
  }
}