.popup__title {
  margin: 0 0 48px 0;
  padding: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  /* text-align: center; */
}
@media (max-width: 420px)  {
  .popup__title {
    margin: 0 0 70px 0;
    font-size: 18px;
    line-height: 22px;
  }
}