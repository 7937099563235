.profile__image {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  transition: opacity .3s;
  cursor: pointer;
}


