.profile__edit-button {
  min-width: 24px;
  min-height: 24px;
  background-image: url('../../../icons/edit-button.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 65%;
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  transition: opacity .3s;
  cursor: pointer;
}

.profile__edit-button:hover {
  opacity: .6;
}

@media (max-width: 366px)  {
  .profile__edit-button {
    min-width: 18px;
    min-height: 18px;
    background-position: 0 55%;
  }
}