.profile__avatar {
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.profile__avatar:hover .profile__edit-avatar{
  opacity: 1;
}

.profile__avatar:hover .profile__image{
  opacity: .2;
}

@media (max-width: 739px)  {
  .profile__avatar {
    margin: 0 0 28px 0;
  }
}