.footer {
  margin: 0 auto;
  padding: 0 0 60px 0;
  max-width: 880px;
  width: 100%;
}

@media (max-width: 480px)  {
  .footer {
    padding: 0 0 36px 0;
  }
}