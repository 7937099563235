.footer__copyright {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
}

@media (max-width: 907px)  {
  .footer__copyright {
    padding: 0 19px;
  }
}

@media (max-width: 480px)  {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}