.profile {
  max-width: 100%;
  margin: 0 0 50px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
}

@media (max-width: 910px)  {
  .profile {
    margin: 0 20px 37px;
  }
}