.logo {
  margin: 0;
  padding: 0;
  width: 142px;
  height: 33px;
}
@media (max-width: 907px)  {
  .logo {
    margin: 0 0 0 27px;
  }
}
@media (max-width: 480px)  {
  .logo {
    width: 105px;
    height: 25px;
  }
}