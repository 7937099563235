.place {
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 10px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}