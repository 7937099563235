.auth__form {
  background-color:  #000000;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 36px 37px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 358px;
  flex: 1;
}