.place__favorite {
  background-image: url("../../../icons/heart.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 19px;
  margin: 0 0 3px 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  transition: opacity .3s;
  cursor: pointer;
}

.place__favorite:hover {
  opacity: .5;
}