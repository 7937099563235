.popup__input {
  width: calc(100% - 4px);
  font-size: 14px;
  line-height: 24px;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.popup__input:focus {
  outline: 0;
  border-bottom: 1px solid rgba(105, 177, 245);
}