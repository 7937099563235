.auth__button {
  margin: 183px 0 0 0;
  padding: 0;
  background-color: #FFFFFF;
  color: #000000;
  min-height: 50px;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  transition: opacity .3s;
}
.auth__button:hover {
  opacity: .8;
}