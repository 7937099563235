.place__cart {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
  height: 19px;
  background-image: url("../../../icons/trash.svg");
  background-repeat: no-repeat;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  transition: opacity .3s;
}

.place__cart:hover {
  opacity: .6;
}

.place__cart-active {
  display: block;
}