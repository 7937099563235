.header__exit {
  margin: 0 0 0 24px;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  color: #FFFFFF;
  transition: opacity .3s;
}

.header__exit:hover {
  opacity: .6;
}